import React from "react";
import JsonData from "../data/data.json";

// Components
import { Header } from "./header";
import { Footer } from "./footer";

const Layout = ({ children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <Footer data={JsonData.Contact} />
  </>
);

export default Layout;
