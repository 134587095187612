import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

// Layout
import Layout from "./components/Layout";

// Pages
import Index from "./pages/Index";
import About from "./pages/about";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import Cropping2022 from "./pages/gallery/cropping-season-training-and-sanitation-2022";
import Cropping2021 from "./pages/gallery/cropping-season-training-and-sanitation-2021";
import Monitoring2021 from "./pages/gallery/monitory-and-evaluation-2021";
import RiceFarm from "./pages/gallery/rice-farm";
import Soyabean from "./pages/gallery/soyabeans";

// Styling
import "animate.css";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route
            path="/cropping-season-training-and-sanitation-2022"
            element={<Cropping2022 />}
          />
          <Route
            path="/cropping-season-training-and-sanitation-2021"
            element={<Cropping2021 />}
          />
          <Route
            path="/monitory-and-evaluation-2021"
            element={<Monitoring2021 />}
          />
          <Route path="/rice-farm" element={<RiceFarm />} />
          <Route path="/soyabeans" element={<Soyabean />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
