import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = ({
    list=[]
}) => {
    return (
        <AutoplaySlider 
            animation="foldOutAnimation" 
            cssModule={[CoreStyles, AnimationStyles]}            
            play={true}
            interval={6000}
            className="h-100"
        >
            {list.map((el, index) => (
                <div className="w-100 h-100 position-relative" key={index}>
                    <img src={el.img} className={`d-block w-100 h-100 object-fit ${el.position || 'object-pos-top'}`} alt="..." />

                    <div className="position-absolute top-50 start-50 translate-middle w-100">
                        <p 
                            className={`${el.hasInfoBackground ? `${el.hasInfoBackground}` : ''} display-2 m-0 p-3 text-center`}
                            // animate__animated animate__lightSpeedInRight
                            data-aos="slide-down" 
                            data-aos-duration="2000"
                        >
                            {el.info}
                        </p>
                    </div>
                </div>
            ))}             
        </AutoplaySlider>
    );
};

export default Slider;