import { useState, useEffect } from "react";
import AOS from 'aos';

import { LandingPage } from "../components/LandingPage";
import { Features } from "../components/features";
import { About } from "../components/about";
import { Traction } from "../components/traction";
import { Tagline } from "../components/tagline";
import { Services } from "../components/services";
import { Partners } from "../components/partners";
import { Gallery } from "../components/gallery";
// import { Testimonials } from "../components/testimonials";
// import { Team } from "../components/Team";
import { Contact } from "../components/contact";
import JsonData from "../data/data.json";

const Index = () => {
  const [landingPageData, setLandingPageData] = useState({});
  
  useEffect(() => {
    setLandingPageData(JsonData);
    window.scrollTo(0, 0);
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    
    });
  }, []);

  return (
    <div>
      <LandingPage data={landingPageData.Header} />
      
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      <Traction data={landingPageData.Traction} />
      <Services data={landingPageData.Services} />
      <Partners data={landingPageData.Partners} />
      <Tagline />
      <Gallery data={landingPageData.Gallery}/>
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Index;
