export const Partners = (props) => {
  return (
    <div id='partners' className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Partners</h2>
        </div>

          <div className="row">
            <div className="d-flex justify-content-between align-content-center">
              {props.data ?
                props.data.map((el, i) => (
                  <img 
                    src={el} 
                    className="" 
                    alt="" 
                    data-aos="fade-up"
                    data-aos-delay={(i+1) * 200} 
                  />
                ))
              : 'loading'}
            </div>
          </div>
      </div>
    </div>
  );
};
