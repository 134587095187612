import React from 'react';
export const Features = (props) => {
  
  return (
    <div id='features' className='text-center py-5'>
      <div className='container'>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div 
                  key={`${d.title}-${i}`} 
                  className='partner__item col-xs-6 col-md-4 px-2'
                  data-aos="fade-up"
                  data-aos-delay={(i+1) * 400}
                  data-aos-duration="500"
                  data-aos-easing="ease-out"
                >
                  <div className="shadow-lg p-4 h-100">
                    <div className="">
                      <img src={d.img} className="w-100 object-pos-top object-fit" height={250} alt="" />
                    </div>

                    <h3 className="txt-primary pt-2">{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
