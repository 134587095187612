import React, { useEffect } from 'react';
import AOS from 'aos';
import { About } from "../components/about";

import JsonData from "../data/data.json";

const AboutUS = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []); 
   
  return (
    <section className="pt-5">
      <About data={JsonData.About} />
    </section>
  )
}

export default AboutUS;
