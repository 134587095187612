import React, { useEffect } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import "../../../node_modules/photoswipe/dist/photoswipe.css";

import JsonData from "../../data/data.json";

const CroppingSeason21 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="croppingSeason21" className="container">
      <div className="pb-4">
        <h2>{JsonData.Gallery[1].title}</h2>
      </div>
      <div className="row">
        <Gallery>
          {JsonData.Gallery[1].images.map((d, i) => (
            <div className="col-md-3 px-2 mb-4 h-80">
              <Item
                original={d}
                thumbnail={d}
                width="1024"
                height="768"
                key={i}
              >
                {({ ref, open }) => (
                  <img
                    className="object-fit w-100 h-100"
                    ref={ref}
                    onClick={open}
                    src={d}
                  />
                )}
              </Item>
            </div>
          ))}
        </Gallery>
      </div>
    </div>
  );
};

export default CroppingSeason21;
