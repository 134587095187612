import React, { useEffect } from 'react';
import AOS from 'aos';
import { Contact } from "../components/contact";
import JsonData from "../data/data.json";

const ContactUs = () => {
  useEffect(() => {
    AOS.init();
    window.scrollTo(0, 0);
  }, []);   
  
  return (
    <section className="pt-5">
      <Contact data={JsonData.Contact} />
    </section>
  )
}

export default ContactUs;
