import React, { useEffect } from 'react';
// import AOS from 'aos';

export const Footer = (props) => {
  const menus = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
      link: "#about",
    },
    {
      name: "Our Products",
      link: "#services",
    },
    {
      name: "Our Projects",
      link: "#portfolio",
    },
    {
      name: "Blog",
      link: "#testimonials",
    },
    {
      name: "Contact",
      link: "#contact",
    },
  ];

  // useEffect(() => {
  //   AOS.init();
  // }, []);
  

  return (
    <footer className="footer-all">
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li className="pb-3">
                      <a
                        href={props.data ? props.data.facebook : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook" data-aos="zoom-in" data-aos-duration="1000"></i>
                      </a>
                    </li>
                    <li className="pb-3">
                      <a
                        href={props.data ? props.data.instagram : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-instagram" data-aos="zoom-in" data-aos-delay="400" data-aos-duration="1000"></i>
                      </a>
                    </li>
                    <li className="pb-3">
                      <a
                        href={props.data ? props.data.linkedin : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000"></i>
                      </a>
                    </li>
                    <li className="pb-3">
                      <a
                        href={props.data ? props.data.youtube : "/"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-youtube" data-aos="zoom-in" data-aos-delay="800" data-aos-duration="1000"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p className="mb-0">
            &copy; 2022 Witama
            {/* <a href='https://georgearthur.netlify.app/' target="_blank" rel="noopener noreferrer">
            MalInc
          </a> */}
          </p>
        </div>
      </div>
    </footer>
  );
};
