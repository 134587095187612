import { useLocation } from "react-router-dom";

export const About = (props) => {
  let location = useLocation();

  return (
    <div id="about">
      <div className="container">
        <div className="row bg-row">
          <div className="col-xs-12 col-md-6">
            <div 
              className="p-2 mb-4 shadow"
              // animate__animated animate__flipInY
              data-aos="fade-up"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"              
            >
              <img src="img/about/002.jpg" className="img-responsive w-100 m-0 p-0" alt="" />{" "}
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <h3>Commited to impactful Agribusiness</h3>
              {location.pathname === '/about-us' ?
                <>
                  <p className="animate__animated animate__fadeIn">
                    Witama Agribusiness, is an impact driven and pro-profit social enterprise into the production of cereals as its core business, agricultural commodity trading and the distribution of agro-inputs.
                    We operate a commercial farm as our nucleus farm and an outgrower scheme which provides our business with reliable and quality supply of grains to our clients.
                    We have much focus on contributing to the livelihood improvement of women farmers and youth, thus, 70 percent of women involved in our outgrower scheme.
                    We have trained technical officers who are continuously with our farmers throughout the cropping season, ensuring that all protocols are adhered to and providing timely field support to our farmers.
                    We're committed to running impactful profitable business with all partners and relevant stakeholders.
                  </p>

                  <p>
                    We're committed to running impactful profitable business with all partners.
                  </p>
                </>
              :
                <p className="animate__animated animate__fadeIn">{props.data ? props.data.paragraph : "loading..."}</p>              
              }
              
              {location.pathname !== '/about-us' && 
                <div className="row d-flex justify-content-end">
                  <div className="w-50 ml-0 p-2 shadow"
                    // animate__animated animate__rotateInUpRight
                    data-aos="fade-left"
                  >
                    <img src="img/about/001.jpg" className="img-responsive object-fit w-100 m-0 p-0" alt="" />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        {location.pathname === '/about-us' && 
          <section className="vision">
            <div className="row">
              <div className="col-xs-12 col-md-5">
                <div className="about-text">
                  <ul>
                    <li 
                      className="d-flex align-items-start justify-content-center m-0"
                      data-aos="fade-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1000" 
                    >
                      <div className="p-2 bg-orange rounded-circle d-flex justify-content-center align-items-center">
                        <i class="fa fa-check text-white"></i>
                      </div>
                      <div className="px-3">
                        <h2>Vision</h2>
                        <div>To be a reliable all year-round suppliers of grains to our customers</div>
                      </div>
                    </li>
                    <li 
                      className="d-flex align-items-start justify-content-center pt-5 m-0"
                      data-aos="fade-up"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="1000" 
                    >
                      <div className="p-2 bg-orange rounded-circle d-flex justify-content-center align-items-center">
                        <i class="fa fa-check text-white"></i>
                      </div>
                      <div className="px-3">
                        <h2>Mission</h2>
                        <div>To produce quality grains and improve rural livelihood through the use of modern agricultural technologies and strategic agribusiness</div>
                      </div>
                    </li>
                  </ul>
                </div>            
              </div>
              <div className="col-xs-12 col-md-7 px-0">
                <div className="mb-4 pl-4" 
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-delay="700"
                  data-aos-duration="1000" 
                >
                  <img src="img/tagline.jpeg" className="img-responsive w-100 m-0" alt="" />
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    </div>
  );
};
