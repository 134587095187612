import React, { useState, useEffect } from 'react'
import JsonData from "../data/data.json";
import jsCookie from 'js-cookie';

import Slider from "./Slider";
import VideoModal from "./Modal";

export const LandingPage = () => {
  const [isOpen, setisOpen] = useState();

  const list = [
    {
      title: "Farmers joint",
      name: "Providing farmers access",
      img: JsonData.landingPage.img01,
      info: "Productivity enhancing technologies resulting to optimum yields"
    },
    {
      title: "Female Farmers",
      name: "Providing farmers access",
      img: JsonData.landingPage.img02,
      position: "object-pos-center",
      hasInfoBackground: "bg-diluted-primary",
      info: "Improving  livelihood of the Ghanaian female farmer"
    },
    {
      title: "Farmers joint",
      name: "Providing farmers access",
      img: JsonData.landingPage.img03,
      position: "object-pos-center",
      info: "Productivity enhancing technologies resulting to optimum yields"
    },
    {
      title: "Agrobusiness",
      name: "Providing farmers access",
      hasInfoBackground: "bg-diluted-white txt-primary",
      img: JsonData.landingPage.img06,
      position: "object-pos-bottom",
      info: "Quality Grains"
    },
  ];

  const handleCloseModal = (val) => {
    jsCookie.set("hideVideoModal", true);
    setisOpen(val);
  };

  useEffect(() => {
    if (jsCookie.get("hideVideoModal")) setisOpen(false);
    else setisOpen(true);
  }, []);

  return (
    <div id="header" className="intro">
      <Slider list={list} />

      <VideoModal 
        isOpen={isOpen} 
        handleCloseModal={handleCloseModal} 
      />
    </div>
  )
}
