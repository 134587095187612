export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Commodities</h2>
        </div>
        <div className='row'>
          <div className="col-xs-12 col-md-6 col-md-offset-6 mx-auto">
            {/* <div className="bg-gray-1 rounded w-50 mx-auto mb-4">
              <h3>Commodities We Trade</h3>
            </div> */}
            
            <div className="d-flex flex-wrap justify-content-evenly">
              {props.data?.commodities ? props.data?.commodities?.map((d, i) => (
                <div 
                  key={`${d.name}-${i}`} 
                  className='col-xs-6 col-md-6 col-md-offset-6'
                  data-aos="zoom-in"
                  data-aos-delay={(i+1) * 500}
                >
                  <div className='service-desc'>
                    <img 
                      src={d.img} 
                      className="img-responsive shadow-lg rounded-circle object-fit w-40 h-40 m-0 p-0 animate__animated animate__tada" 
                      alt="" 
                    />
                    <p className="pt-3">{d.name}</p>
                  </div>
                </div>
              )) : 'loading'}
            </div>
          </div>

          {/* <div className="col-xs-12 col-md-6">
            <div className="bg-orange-1 rounded w-50 mx-auto mb-4">
              <h3>Services We Offer</h3>
            </div>
            
            <div className="d-flex flex-wrap justify-content-evenly">
              {props.data?.offers ? props.data?.offers?.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-xs-6'>
                  <div className='service-desc'>
                    <img 
                      src={d.img} 
                      className="img-responsive shadow-lg rounded-circle object-fit w-40 h-40 m-0 p-0 animate__animated animate__tada" 
                      alt="" 
                    />
                    <p className="pt-3">{d.name}</p>
                  </div>
                </div>
              )) : 'loading'}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
