
export const Tagline = () => {

  return (
    <section className="tagline">
      <div className="overlay" style={{ backgroundImage: "url('img/tagline.jpg')" }} />

      <div id="tagline" className="d-flex justify-content-center align-items-center">
        <div className="service mx-auto py-4 text-center m-0 animate__animated animate__fadeIn">
          <h2 className="text-white">
            Commited to impactful Agribusiness
          </h2>
          <button className="">
            <a href="https://www.youtube.com/watch?v=qbexaY5CDAI&ab_channel=WitamaAgribusinessltd" target="_blank" rel="noopener noreferrer">
              Watch Video
            </a>
          </button>
        </div>
      </div>
    </section>
  );
};
