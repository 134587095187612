import React from 'react';
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/scss/modal-video.scss"

const Modal = ({
  channel="youtube",
  videoId="qbexaY5CDAI",
  isOpen=false,
  handleCloseModal=() => {},
}) => {
  return (
    <ModalVideo 
      channel={channel} 
      autoplay 
      isOpen={isOpen} 
      videoId={videoId} 
      onClose={() => handleCloseModal(false)} 
    />
  );
};

export default Modal;