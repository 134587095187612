import { Image } from "./image";

export const Gallery = (props) => {
  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Gallery</h2>
        </div>
        <div className='row'>
            {props.data
              ? props.data.map((d, i) => (
                <div 
                  key={`${d.title}-${i}`} 
                  className='col-sm-6 col-md-4 col-lg-4'
                  data-aos={(i+1) % 2 ? 'flip-left' : 'flip-right'}
                  data-aos-delay={(i+1) * 400} 
                  data-aos-duration="500"
                >
                  <Image title={d.title} smallImage={d.preview} link={d.link} />
                </div>
              ))
              : 'Loading...'}
        </div>
      </div>
    </div>
  )
}
