import NumberCounter from 'number-counter';

export const Traction = (props) => {

  return (
    <div id="traction">
      <div className="container">
        <div className="row text-black">
            <div className="d-flex flex-wrap justify-content-evenly">
              {props.data ? props.data?.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-xs-6 col-md-3'>
                  <div className='text-center service-desc'>
                    <h1 className="d-flex justify-content-center">
                      <NumberCounter start={0} end={d.value} delay={5} />
                      <span>{d.postFix}</span>
                    </h1>
                    <p className="pt-3">{d.name}</p>
                  </div>
                </div>
              )) : 'loading'}
            </div>
        </div>
      </div>
    </div>
  );
};
