/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";

export const Image = ({ title, smallImage, link="" }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <Link to={link}>
          <div title={title} data-lightbox-gallery="gallery1">
            <div className="hover-text">
              <h4>{title}</h4>
            </div>
            <img src={smallImage} className="img-responsive w-100 h-80 object-fit" alt={title} />
          </div>
        </Link>
      </div>
    </div>
  );
};
