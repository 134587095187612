import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export const Header = (props) => {
  let location = useLocation();
  const [showMenu, setshowMenu] = useState(false);

  const nav_list = [
    {
      name: "Home",
      link: "/"
    },
    {
      name: "About Us",
      link: "/about-us"
    },
    // {
    //   name: "Blog",
    //   link: "/blog"
    // },
    {
      name: "Contact Us",
      link: "/contact-us"
    }
  ];

  return (
    <header className="site-header fixed-top shadow-b">
      <nav className="navbar navbar-expand-lg navbar-scroll h-100 bg-white">
        <div className="container h-100">
          <Link to="/" className="navbar-brand page-scroll p-0 mx-3 d-md-none" href="/">
            <img src="img/witamal.png" width={120} className="img-responsive pl-2" alt="" />
          </Link> 

          <button
            className="navbar-toggler mr-4"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#collapse-header"
            aria-controls="collapse-header"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setshowMenu(!showMenu)}
          >
            <span className="w-100 navbar-toggler-icon d-flex justify-content-start align-items-center" >
              <i className="fa fa-bars"></i>
            </span>
          </button>

          <div className={`collapse navbar-collapse h-100 transition-all ${showMenu ? 'collapse-header' : ''}`}>
            <div className="flex-row d-none d-md-block">
              <Link to="/" className="navbar-brand page-scroll py-0" href="/">
                <img src="img/witamal.png" width={120} className="img-responsive" alt="" />
              </Link>               
            </div>

            <ul className="navbar-nav ml-auto mb-2 mb-lg-0" onClick={() => setshowMenu(!showMenu)}>
              {nav_list.map((el, index) => (
                <li className={`nav-item d-flex align-items-center ${location.pathname === el.link ? 'active' : ''}`} key={index}>
                  <Link to={el.link} className="nav-link h-100 w-100 d-flex align-items-center text-sm">
                    {el.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>      
    </header>    
  );
};
